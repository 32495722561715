<template>
    <div class="add-box">
        <i-header :title="title" @back-event="toBack"></i-header>
        <div class="cnt-box">
            <div>{{ message }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    components: {},
    data() {
        return {
            title: "情况说明",
            loading: false,
            message: "",
        };
    },
    created() {
        this.init();
        //初始化

        this.message = this.$route.query.situationDescription;
    },
    methods: {
        async init() {},
        toBack() {
            this.$router.back();
        },
    },
};
</script>

<style lang="less" scoped>
.add-box {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    .cnt-box {
        padding: 0 0.25rem;
        box-sizing: border-box;
    }
    .submit-btn {
        width: 88%;
        height: 0.45rem;
        line-height: 0.45rem;
        border-radius: 0.3rem;
        margin: 11.73vw auto 0 auto;
        font-size: 0.15rem;
        background: var(--themeColor);
        display: block;
        color: #fff;
        text-align: center;
    }
}
</style>
